<div class="h-100">
  <div class="row h-100 g-0">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3>Perfect Balance</h3>
                <p>
                  ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of
                  unified Vue Bootstrap Components and Elements.
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3>Scalable, Modular, Consistent</h3>
                <p>
                  Easily exclude the components you don't require. Lightweight, consistent
                  Bootstrap based styles across all elements and components
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
              <div class="slide-img-bg opacity-6"></div>
              <div class="slider-content text-light">
                <h3>Complex, but lightweight</h3>
                <p>
                  We've included a lot of components that cover almost all use cases for
                  any type of application.
                </p>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-8 col-lg-6">
        <div class="app-logo"></div>
        <h4>
          <div>Forgot your Password?</div>
          <span>Use the form below to recover it.</span>
        </h4>
        <div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <fieldset class="mb-3" id="__BVID__133">
                  <div tabindex="-1" role="group">
                    <label for="exampleEmail" class="form-label">Email</label>
                    <input id="exampleEmail" name="email" type="email" placeholder="Email here..." class="form-control">
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <h6 class="mb-0"><a [routerLink]="" class="text-primary">Sign in existing
                  account</a></h6>
              <div class="ms-auto">
                <button type="button" class="btn btn-primary btn-lg">Recover Password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>