<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: 690px;">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-rocket"></i>
                    <span class="vsm-title">Dashboards</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/dashboards/analytics" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Schema</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a [routerLink]="['/dashboards/advertisement/']" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Prognosen</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/management" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Schema</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/helpdesk" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Alarme</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/monitoring" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Livebetrieb</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/crypto" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Zähler</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/project-management" routerLinkActive="active-item" (click)="activeId='dashboardsMenu'" class="vsm-link">
                          <span class="vsm-title">Räume</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/product" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Produkte</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/dashboards/statistics" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Statistiken</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="pagesMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-browser"></i>
                    <span class="vsm-title">Prüfbericht</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/login" class="vsm-link">
                          <span class="vsm-title">Login</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/login-boxed" class="vsm-link">
                          <span class="vsm-title">Login Boxed</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/register" class="vsm-link">
                          <span class="vsm-title">Register</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/register-boxed" class="vsm-link">
                          <span class="vsm-title">Register Boxed</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/forgot-password" class="vsm-link">
                          <span class="vsm-title">Forgot Password</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/pages/forgot-password-boxed" class="vsm-link">
                          <span class="vsm-title">Forgot Password Boxed</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="apps">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-plugin"></i>
                    <span class="vsm-title">Addons</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/mailbox" class="vsm-link">
                          <span class="vsm-title">Mailbox</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/chat" class="vsm-link">
                          <span class="vsm-title">Chat</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/apps/material-tabs" class="vsm-link">
                          <span class="vsm-title">Material Tabs</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Bootstrap Components</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="elements">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-diamond"></i>
                    <span class="vsm-title">FAQ</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-standard" class="vsm-link">
                          <span class="vsm-title">Standard Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-pills" class="vsm-link">
                          <span class="vsm-title">Pills Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-square" class="vsm-link">
                          <span class="vsm-title">Square Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-shadow" class="vsm-link">
                          <span class="vsm-title">Shadow Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-icons" class="vsm-link">
                          <span class="vsm-title">Icons Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link">
                          <span class="vsm-title">Dropdowns</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/icons" class="vsm-link">
                          <span class="vsm-title">Icons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/badges-labels" class="vsm-link">
                          <span class="vsm-title">Badges</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/cards" class="vsm-link">
                          <span class="vsm-title">Cards</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/loaders" class="vsm-link">
                          <span class="vsm-title">Loading Indicators</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/list-group" class="vsm-link">
                          <span class="vsm-title">List Groups</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/navigation-menu" class="vsm-link">
                          <span class="vsm-title">Navigation Menus</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/timeline" class="vsm-link">
                          <span class="vsm-title">Timeline</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/utilities" class="vsm-link">
                          <span class="vsm-title">Utilities</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="components">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-car"></i>
                    <span class="vsm-title">Hilfe</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <!-- <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/tabs" class="vsm-link">
                          <span class="vsm-title">Tabs</span>
                        </a>
                      </div> -->
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/accordions" class="vsm-link">
                          <span class="vsm-title">Accordions</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/notifications" class="vsm-link">
                          <span class="vsm-title">Notifications</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/modals" class="vsm-link">
                          <span class="vsm-title">Modals</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/progress-bar" class="vsm-link">
                          <span class="vsm-title">Progress Bar</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/tooltips-popovers" class="vsm-link">
                          <span class="vsm-title">Tooltips &amp; Popovers</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/carousel" class="vsm-link">
                          <span class="vsm-title">Carousel</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/calendar" class="vsm-link">
                          <span class="vsm-title">Calendar</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/pagination" class="vsm-link">
                          <span class="vsm-title">Pagination</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/count-up" class="vsm-link">
                          <span class="vsm-title">Count Up</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/scrollable-elements" class="vsm-link">
                          <span class="vsm-title">Scrollable</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/maps" class="vsm-link">
                          <span class="vsm-title">Maps</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/ratings" class="vsm-link">
                          <span class="vsm-title">Ratings</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/image-crop" class="vsm-link">
                          <span class="vsm-title">Image Crop</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="form-elements">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Kontakt</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-elements/controls" class="vsm-link">
                          <span class="vsm-title">Controls</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-elements/layouts" class="vsm-link">
                          <span class="vsm-title">Layouts</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-elements/validation" class="vsm-link">
                          <span class="vsm-title">Validation</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="form-widgets">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-shield"></i>
                    <span class="vsm-title">Form Widgets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/datepicker" class="vsm-link">
                          <span class="vsm-title">Datepicker</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/timepicker" class="vsm-link">
                          <span class="vsm-title">Timepicker</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/typeahead" class="vsm-link">
                          <span class="vsm-title">Typeahead</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/range-slider" class="vsm-link">
                          <span class="vsm-title">Range Slider</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/input-selects" class="vsm-link">
                          <span class="vsm-title">Input Selects</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/toggle-switch" class="vsm-link">
                          <span class="vsm-title">Toggle Switch</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/wysiwyg-editors" class="vsm-link">
                          <span class="vsm-title">WYSIWYG Editors</span>
                        </a>
                      </div>
                      <!-- <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/input-mask" class="vsm-link">
                          <span class="vsm-title">Input Mask</span>
                        </a>
                      </div> -->
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/clipboard" class="vsm-link">
                          <span class="vsm-title">Clipboard</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/textarea-autosize" class="vsm-link">
                          <span class="vsm-title">Textarea Autosize</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/colorpicker" class="vsm-link">
                          <span class="vsm-title">Color Picker</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-widgets/dropzone" class="vsm-link">
                          <span class="vsm-title">Dropzone</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="material">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-safe"></i>
                    <span class="vsm-title">Tables</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/tables/bootstrap" class="vsm-link">
                          <span class="vsm-title">Bootstrap 5 Tables</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/material/table" class="vsm-link">
                          <span class="vsm-title">Material Tables</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/material/paginator" class="vsm-link">
                          <span class="vsm-title">Material Paginator</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Material Components</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="form-controls">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ribbon"></i>
                    <span class="vsm-title">Form Controls</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/auto-complete" class="vsm-link">
                          <span class="vsm-title">Auto Complete</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/checkbox" class="vsm-link">
                          <span class="vsm-title">Checkbox</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/radio" class="vsm-link">
                          <span class="vsm-title">Radio Button</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/datepicker" class="vsm-link">
                          <span class="vsm-title">Datepicker</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/form-field" class="vsm-link">
                          <span class="vsm-title">Form Field</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/input" class="vsm-link">
                          <span class="vsm-title">Input</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/select" class="vsm-link">
                          <span class="vsm-title">Select</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/slider" class="vsm-link">
                          <span class="vsm-title">Slider</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/form-controls/slider-toggle" class="vsm-link">
                          <span class="vsm-title">Slider Toggle</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="layout">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-science"></i>
                    <span class="vsm-title">Layout</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/menu" class="vsm-link">
                          <span class="vsm-title">Menu</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/cards" class="vsm-link">
                          <span class="vsm-title">Cards</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/divider" class="vsm-link">
                          <span class="vsm-title">Divider</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/expansion-panel" class="vsm-link">
                          <span class="vsm-title">Expansion Panel</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/grid-list" class="vsm-link">
                          <span class="vsm-title">Grid list</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/list" class="vsm-link">
                          <span class="vsm-title">List</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/tabs" class="vsm-link">
                          <span class="vsm-title">Tabs</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/stepper" class="vsm-link">
                          <span class="vsm-title">Stepper</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/tree" class="vsm-link">
                          <span class="vsm-title">Tree</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/snackbar" class="vsm-link">
                          <span class="vsm-title">Snackbar</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/layout/tooltip" class="vsm-link">
                          <span class="vsm-title">Tooltip</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="button-indicators">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-radio"></i>
                    <span class="vsm-title">Buttons & Indicators</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/buttons" class="vsm-link">
                          <span class="vsm-title">Buttons</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/button-toggle" class="vsm-link">
                          <span class="vsm-title">Button Toggle</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/chips" class="vsm-link">
                          <span class="vsm-title">Chips</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/progress-bar" class="vsm-link">
                          <span class="vsm-title">Progress Bar</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/progress-spinner" class="vsm-link">
                          <span class="vsm-title">Progress Spinner</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/button-indicators/ripples" class="vsm-link">
                          <span class="vsm-title">Ripples</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Dashboard Boxes</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="widgets">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Chart Boxes</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-1" class="vsm-link">
                          <span class="vsm-title">Variation 1</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-2" class="vsm-link">
                          <span class="vsm-title">Variation 2</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-3" class="vsm-link">
                          <span class="vsm-title">Variation 3</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="vsm-item">
              <a routerLink="/widgets/profile-boxes" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Profile Boxes</span>
              </a>
            </div>
            <div class="vsm-header">Charts</div>
            <div class="vsm-item">
              <a routerLink="/charts/chartjs" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph2"></i>
                <span class="vsm-title">ChartJS</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/charts/apexcharts" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph"></i>
                <span class="vsm-title">Apex Charts</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/charts/gauges" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-gleam"></i>
                <span class="vsm-title">Gauges</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/charts/sparklines" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-graph1"></i>
                <span class="vsm-title">Chart Sparklines</span>
              </a>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>