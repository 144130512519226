<div class="header-dots">
  <div class="btn-group" ngbDropdown placement="bottom">
    <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-primary"></div>
          <i class="lnr-menu text-primary"></i>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-plum-plate">
          <div class="menu-header-image dd-header-bg-1"></div>
          <div class="menu-header-content text-white">
            <h5 class="menu-header-title">Grid Dashboard</h5>
            <h6 class="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
          </div>
        </div>
      </div>
      <div class="grid-menu grid-menu-xl grid-menu-3col">
        <div class="g-0 row">
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Automation
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Reports
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Settings
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Content
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Activity
            </button>
          </div>
          <div class="col-sm-6 col-xl-4">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
              <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
              Contacts
            </button>
          </div>
        </div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item-divider nav-item"></li>
        <li class="nav-item-btn text-center nav-item">
          <button class="btn-shadow btn btn-primary btn-sm">Follow-ups</button>
        </li>
      </ul>
    </div>
  </div>
  <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom">
    <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-danger"></div>
          <i class="lnr-bullhorn text-danger"></i>
          <div class="badge badge-dot badge-dot-sm bg-danger">Notifications</div>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-deep-blue">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-dark">
            <h5 class="menu-header-title">Notifications</h5>
            <h6 class="menu-header-subtitle">You have <b>21</b> unread messages</h6>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <div class="card-tabbed-header">
          <ul ngbNav #nav="ngbNav" class="nav-tabs tabs-animated tabs-animated-shadow" justify="justified">
            <li ngbNavItem>
              <a ngbNavLink><span>Messages</span></a>
              <ng-template ngbNavContent>
                <div class="scroll-gradient">
                  <div class="scroll-area-sm shadow-overflow">
                    <perfect-scrollbar [autoPropagation]="true">
                      <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands Meeting</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the production release</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands Meeting</h4>
                                <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">FontAwesome Icons</h4>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the production release</h4>
                                <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-warning">12:00 PM</b></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink><span>Events</span></a>
              <ng-template ngbNavContent>
                <div class="scroll-gradient">
                  <div class="scroll-area-sm shadow-overflow">
                    <perfect-scrollbar [autoPropagation]="true">
                      <div
                        class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-success"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands
                                Meeting</h4>
                              <p>Lorem ipsum dolor sic amet, today at <a [routerLink]="">12:00 PM</a></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-warning"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-danger"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the
                                production release</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
                                magna elit enim at minim veniam quis nostrud</p><span
                                class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-primary"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">Something not important</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam quis nostrud</p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-success"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">All Hands
                                Meeting</h4>
                              <p>Lorem ipsum dolor sic amet, today at <a [routerLink]="">12:00 PM</a></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-warning"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                              <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-danger"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">Build the
                                production release</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
                                magna elit enim at minim veniam quis nostrud</p>
                                <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-item vertical-timeline-element">
                          <div><span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl bg-primary"></i></span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title text-success">Something not important</h4>
                              <p>Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam quis nostrud</p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink><span>Status</span></a>
              <ng-template ngbNavContent>
                <div class="no-results">
                  <div class="swal2-icon swal2-success swal2-animate-success-icon">
                    <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                    <span class="swal2-success-line-tip"></span><span class="swal2-success-line-long"></span>
                    <div class="swal2-success-ring"></div>
                    <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                    <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                  </div>
                  <div class="results-subtitle">All caught up!</div>
                  <div class="results-title">There are no system errors!</div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item mt-0"></li>
          <li class="nav-item-btn text-center nav-item">
            <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">View Latest Changes</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="btn-group" ngbDropdown placement="bottom">
    <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-focus"></div>
          <div class="language-icon flag flag-icon-background flag-icon-de"></div>
        </div>
      </span>
    </button>
    <div ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
          <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
          <div class="menu-header-content text-center text-white">
            <h6 class="menu-header-subtitle mt-0">Choose Language</h6>
          </div>
        </div>
      </div>
      <h6 class="dropdown-header">Popular Languages</h6>
      <button type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-us"></div>
        <span>USA</span>
      </button>
      <button type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-ch"></div>
        <span>Switzerland</span>
      </button>
      <button type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-fr"></div>
        <span>France</span>
      </button>
      <button type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-es"></div>
        <span>Spain</span>
      </button>
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header">Others</h6>
      <button type="button" class="dropdown-item active">
        <div class="language-icon flag flag-icon-background flag-icon-de"></div>
        <span>Germany</span>
      </button>
      <button type="button" class="dropdown-item">
        <div class="language-icon flag flag-icon-background flag-icon-it"></div>
        <span>Italy</span>
      </button>
    </div>
  </div>
  <div class="btn-group" ngbDropdown>
    <button type="button" class="btn btn-link p-0" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-success"></div>
          <i class="lnr-pie-chart text-success"></i>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" autoClose="outside" ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-premium-dark">
          <div class="menu-header-image dd-header-bg-7"></div>
          <div class="menu-header-content text-white">
            <h5 class="menu-header-title">Users Online</h5>
            <h6 class="menu-header-subtitle">Recent Account Activity Overview</h6>
          </div>
        </div>
      </div>
      <div class="widget-chart">
        <div class="widget-chart-content">
          <div class="icon-wrapper rounded-circle">
            <div class="icon-wrapper-bg opacity-9 bg-focus"></div>
            <i class="lnr-users text-white"></i>
          </div>
          <div class="widget-numbers"><span>344k</span></div>
          <div class="widget-subheading pt-2">Profile views since last login</div>
          <div class="widget-description text-danger"><span class="pe-1"><span>176%</span></span>
            chart
          </div>
        </div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item-divider mt-0 nav-item"></li>
        <li class="nav-item-btn text-center nav-item">
          <button class="btn-shine btn-wide btn-pill btn btn-warning btn-sm">
            <!-- <fa name="refresh" animation="spin"></fa> -->
            Refresh List
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>