<div class="h-100">
  <div class="row h-100 g-0">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3>Perfect Balance</h3>
                <p>
                  ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of
                  unified Vue Bootstrap Components and Elements.
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3>Scalable, Modular, Consistent</h3>
                <p>
                  Easily exclude the components you don't require. Lightweight, consistent
                  Bootstrap based styles across all elements and components
                </p>
              </div>
            </div>
          </div>
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
              <div class="slide-img-bg opacity-6"></div>
              <div class="slider-content text-light">
                <h3>Complex, but lightweight</h3>
                <p>
                  We've included a lot of components that cover almost all use cases for
                  any type of application.
                </p>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="app-logo"></div>
        <h4 class="mb-0">
          <div>Welcome back,</div>
          <span>Please sign in to your account.</span>
        </h4>
        <h6 class="mt-3">
          No account?
          <a [routerLink]="" class="text-primary">Sign up now</a></h6>
        <div class="divider"></div>
        <div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <fieldset class="mb-3">
                  <div tabindex="-1" role="group">
                    <label for="exampleEmail" class="form-label">Email</label>
                    <input id="exampleEmail" name="email" type="email" placeholder="Email here..." class="form-control">
                  </div>
                </fieldset>
              </div>
              <div class="col-md-6">
                <fieldset class="">
                  <div tabindex="-1" role="group"><label for="examplePassword" class="form-label">Password</label><input
                      id="examplePassword" name="password" type="password" placeholder="Password here..."
                      class="form-control"></div>
                </fieldset>
              </div>
            </div>
            <div class="custom-control custom-checkbox form-check">
              <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" value="true">
              <label for="exampleCheck" class="form-label form-check-label">
                Keep me logged in
              </label></div>
            <div class="divider"></div>
            <div class="d-flex align-items-center">
              <div class="ms-auto"><a [routerLink]="" class="btn-lg btn btn-link">Recover
                  Password</a>
                <button type="button" class="btn btn-primary btn-lg">Login to Dashboard</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>